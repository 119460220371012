import React from "react";
import styled from "styled-components";
import BaseProductCard from "./BaseProductCard";
import { GetBaseProductsQuery } from "../../generated/graphql";

type BaseProducts = GetBaseProductsQuery['baseProducts'];
// TODO: eventually tackle type duplication

type ComponentProps = {
  baseProducts: BaseProducts
}

const BaseProductsDisplayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProductList = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const BaseProductsDisplay: React.FC<ComponentProps> = ({ baseProducts }) => {
  return (
    <BaseProductsDisplayContainer>
      <ProductList>
        {
          baseProducts.map((product, index) => <BaseProductCard baseProduct={product} key={index}/>)
        }
      </ProductList>
    </BaseProductsDisplayContainer>
  );
};

export default BaseProductsDisplay;
import React from "react";
import Image from "../../../components/common/display/ImageWithFallbackImage";
import styled from "styled-components";
import { Header1 } from "../../../components/common/display/Headers";
import { Size, TextAlign } from "../../../types/styling";
import { TextBlock } from "../../../components/common/display/Text";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const IntroContainer = styled.div`
  width: 100vw;
  background: #07223F;
  color: white;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  position: relative; /* Ensures no unexpected margins from parent */
  left: calc(-50vw + 50%); /* Centers it to cover entire screen width */
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%)
`;

const IntroContentContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 150px 0;
  color: #FFFFFF;
  width: 80%;
`;
// Hacky, but reverting the width to 80% since the IntroContainer is 100% to make the BG color full width

const TextContentContainer = styled.div`
  display: grid;
  margin: 4.5em;
`;

const DiscoverCtaButton = styled.button`
  background: #B0DDFF;
  margin: 0 4.5em;
  width: 50%;
  border-radius: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const DiscoverCtaText = styled.span`
  text-align: left;
  font-size: 1.5em;
  padding: 0.5em;
  color: black;
  font-weight: bold;
`;

const DiscoverCtaArrow = styled(FaArrowRight)`
  color: black;
  height: 100%;
`;

const DiscoverCta = () => {
  const navigate = useNavigate();
  const navigateProducts = () => {
    navigate("/base_products");
  };

  return (
    <DiscoverCtaButton onClick={navigateProducts} >
      <DiscoverCtaText>Discover Products</DiscoverCtaText>
      <DiscoverCtaArrow />
    </DiscoverCtaButton>
  );
};

const Intro = () => {
  return (
    <IntroContainer>
      <IntroContentContainer>
        <TextContentContainer>
          <Header1 text="Find your next product creation partner." textAlign={TextAlign.LEFT} size={Size.LARGE} />
          <TextBlock text="OurVersion is the easiest way to discover and work with brands on your next white label, private label, or branded collaboration products." />
        </TextContentContainer>
        <Image imageURL="aboutUs/description.png" description="description" />
        <DiscoverCta />
      </IntroContentContainer>
    </IntroContainer>
  );
};

export default Intro;

import React, { ReactNode } from "react";
import styled from "styled-components";

type ComponentProps = {
  children: ReactNode;
}

const MainAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainAreaContent = styled.div`
  width: 80%;
`;

const MainArea: React.FC<ComponentProps> = ({ children }) => {
  return (
    <MainAreaContainer>
      <MainAreaContent>
        {children}
      </MainAreaContent>
    </MainAreaContainer>
  );
};

export default MainArea;
import React from "react";
import styled from "styled-components";
import BaseProductOrderForm from "./form";
import BaseProductOrderSummary from "./summary";
import VerticalDivider from "../../components/common/display/VerticalDivider";
import { GetBaseProductQuery } from "../../generated/graphql";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const BaseProductOrderDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BaseProductOrderDisplay: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <BaseProductOrderDisplayContainer>
      <BaseProductOrderForm baseProduct={baseProduct} />
      <VerticalDivider />
      <BaseProductOrderSummary baseProduct={baseProduct} />
    </BaseProductOrderDisplayContainer>
  );
};

export default BaseProductOrderDisplay;
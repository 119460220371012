import React from "react";
import styled from "styled-components";
import { GetBaseProductQuery } from "../../../generated/graphql";
import ImageWithFallbackIcon from "../../../components/products/ImageWithFallbackIcon";
import { Header3 } from "../../../components/common/display/Headers";
import { TextLine } from "../../../components/common/display/Text";
import { TextAlign } from "../../../types/styling";


type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const SummaryHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 2em 0 1em;
`;

const ImageContainer = styled.div`
  height: 72px;
`;

const ProductNameContainer = styled.div`
  margin: 0 2em;
`;

const SummaryHeader: React.FC<ComponentProps> = ({ baseProduct } ) => {
  return (
    <SummaryHeaderContainer>
      <ImageContainer>
        <ImageWithFallbackIcon imageURL={baseProduct.productImage} description={baseProduct.productName} size={72} />
      </ImageContainer>
      <ProductNameContainer>
        <Header3 text={baseProduct.productName} textAlign={TextAlign.LEFT} />
        <TextLine text={`Minimum Order Quantity: ${baseProduct.minimumOrderQuantity}`} textAlign={TextAlign.LEFT} />
      </ProductNameContainer>
    </SummaryHeaderContainer>
  );
};

export default SummaryHeader;
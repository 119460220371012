import React from "react";
import styled from "styled-components";
import { GetBaseProductQuery } from "../../../generated/graphql";
import ImageWithFallbackIcon from "../../../components/products/ImageWithFallbackIcon";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const MainContentPhotoContainer = styled.div`
  width: 45%;
  padding: 0 2em 0 2em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;



const MainContentPhoto: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <MainContentPhotoContainer>
      <ImageWithFallbackIcon imageURL={baseProduct.productImage} description="Description" />
    </MainContentPhotoContainer>
  );
};

export default MainContentPhoto;
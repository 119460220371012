import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';

let csrfToken: string | null = null;

// Middleware to set the CSRF token in headers
const csrfMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-CSRF-Token': csrfToken || '', // Include the token if available
    },
  }));
  return forward(operation);
});

// Afterware to extract the CSRF token from response headers
const csrfAfterware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const responseHeaders = context.response?.headers;
    if (responseHeaders) {
      const token = responseHeaders.get('X-CSRF-Token');
      if (token) {
        csrfToken = token; // Cache the token for future requests
      }
    }
    return response;
  });
});

const buildHttpLink = () => new HttpLink({
  uri: `${process.env.REACT_APP_API_ROOT_URL}/graphql`,
  credentials: 'include',
});

const buildClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([csrfMiddleware, csrfAfterware, buildHttpLink()]),
    cache: new InMemoryCache(),
  });
};

const client = buildClient();

export default client;
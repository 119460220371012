import React from "react";
import styled from "styled-components";
import { GetBaseProductsQuery } from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import ImageWithFallbackIcon from "../../components/products/ImageWithFallbackIcon";

type BaseProduct = GetBaseProductsQuery['baseProducts'][number];

type ComponentProps = {
  baseProduct: BaseProduct
}

const BaseProductCardContainer = styled.div`
  height: 25em;
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ProductImageContainer = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
`;

const ProductName = styled.div`
  margin: 0.5em 3em;
  text-align: left;
  font-size: 1em;
  font-weight: bold;
  width: 100%;
`;

const CompanyName = styled.div`
  margin: 0.125em 3em;
  text-align: left;
  width: 100%;
`;

const ProductPriceRange = styled.div`
  margin: 0.125em 3em;
  text-align: left;
  width: 100%;
`;

const BaseProductCard: React.FC<ComponentProps> = ({ baseProduct }) => {
  const pricings = baseProduct.quantityTierPricings.map(({ priceAmount }) => priceAmount).sort().reverse();
  const minPrice = pricings[0];
  const maxPrice = pricings[pricings.length - 1];
  const productPriceRange = (minPrice && maxPrice) ? `$${minPrice} - $${maxPrice}` : "TBD";

  const navigate = useNavigate();

  const navigateToProduct = () => {
    navigate(`/base_products/${baseProduct.id}`);
  };

  return (
    <BaseProductCardContainer onClick={navigateToProduct} >
      <ProductImageContainer>
        <ImageWithFallbackIcon imageURL={baseProduct.productImage} description="Description" />
      </ProductImageContainer>
      <ProductName>{baseProduct.productName}</ProductName>
      <CompanyName>{baseProduct.companyName}</CompanyName>
      <ProductPriceRange>{productPriceRange}</ProductPriceRange>
    </BaseProductCardContainer>
  );
};

export default BaseProductCard;
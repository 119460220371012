import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HomePage from './pages/HomePage';
import BaseProductPage from './pages/BaseProductPage';
import BaseProductOrderPage from './pages/BaseProductOrderPage';
import BaseProductsPage from './pages/BaseProductsPage';
import Header from './components/header';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/base_products" element={<BaseProductsPage />} />
            <Route path="/base_products/:id" element={<BaseProductPage />} />
            <Route path="/base_products/:id/order" element={<BaseProductOrderPage />} />
          </Routes>
          {/* <Footer /> */}
        </div>
      </Router>
    </div>
  );
}

export default App;

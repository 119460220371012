import React from "react";
import { Size, SizeStyleMap } from "../../../types/styling";

type ComponentProps = {
  text: string,
  padding?: React.CSSProperties["padding"],
  margin?: React.CSSProperties["margin"],
  textAlign?: CanvasTextAlign,
  size?: Size,
}

const buildStyle = (
  size: Size,
  sizeStyleMap: SizeStyleMap,
  textAlign: CanvasTextAlign,
  padding: React.CSSProperties["padding"],
  margin: React.CSSProperties["margin"],
) => {
  return {
    ...sizeStyleMap[size],
    textAlign,
    padding,
    margin
  };
};

const paragraphSizeStyleMap: SizeStyleMap = {
  [Size.SMALL]: {
    fontSize: "1.25em"
  },
  [Size.MEDIUM]: {
    fontSize: "1.75em"
  },
  [Size.LARGE]: {
    fontSize: "2.25em"
  }
};

export const TextBlock: React.FC<ComponentProps> = ({
  text,
  size = Size.SMALL,
  textAlign = "left",
  padding = "0.125em",
  margin = "0"
}) => {
  const style = buildStyle(size, paragraphSizeStyleMap, textAlign, padding, margin);
  return (
    <p style={style} >{text}</p>
  );
};

const spanSizeStyleMap: SizeStyleMap = {
  [Size.SMALL]: {
    fontSize: "1.25em"
  },
  [Size.MEDIUM]: {
    fontSize: "1.75em"
  },
  [Size.LARGE]: {
    fontSize: "2.25em"
  }
};

export const TextLine: React.FC<ComponentProps> = ({
  text,
  size = Size.SMALL,
  textAlign = "left",
  padding = "0.125em",
  margin = "0"
}) => {
  const style = buildStyle(size, spanSizeStyleMap, textAlign, padding, margin);
  return (
    <span style={style} >{text}</span>
  );
};
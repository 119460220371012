import React, { useState } from "react";
import { BsBoxSeamFill } from "react-icons/bs";
import Image from "../common/display/Image";

type ComponentProps = {
  imageURL?: string | null
  description: string
  size?: number
}

//  uses product icon as fallback
const ImageWithFallbackIcon: React.FC<ComponentProps> = ({ imageURL, description, size = 200 }) => {
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  return (
    <>
     {
      error ? <BsBoxSeamFill size={size} /> :
        <Image
          src={imageURL || undefined}
          alt={description}
          onError={handleError}
        />
     }
    </>
  );
};

export default ImageWithFallbackIcon;
import React, { useState } from "react";
import Image from "./Image";

type ComponentProps = {
  imageURL: string | null | undefined
  description: string
  fallbackImage?: string
}

const ImageContainer: React.FC<ComponentProps> = ({ imageURL, description, fallbackImage }) => {
  const [src, setSrc] = useState(imageURL || undefined);

  const handleError = () => {
    if (fallbackImage) {
      setSrc(fallbackImage);
    }
  };

  return (
    <Image
      src={src}
      alt={description}
      onError={handleError}
    />
  );
};

export default ImageContainer;
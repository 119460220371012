import React from 'react';

interface HorizontalDividerProps {
  width?: string;        // Width of the divider (e.g., '100%', '50%', '80%')
  thickness?: string;    // Thickness of the divider (e.g., '2px', '4px')
  color?: string;        // Color of the divider (default #ddd)
  margin?: string;       // Margin around the divider (default '20px 0')
}

const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
  width = '100%',
  thickness = '2px',
  color = '#ddd',
  margin = '0'
}) => {
  return (
    <hr
      className="divider"
      style={{
        width: width,
        height: thickness,
        backgroundColor: color,
        border: 'none',
        margin: margin,
      }}
    />
  );
};

export default HorizontalDivider;
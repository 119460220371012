import React, { useState } from "react";
import styled from "styled-components";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

const DetailDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 0.75em;
  background: #F6F6F6;
  border-radius: 6px;
`;

type ComponentProps = {
  name: string;
  content: string;
}

const NameContainer = styled.div`
  background: #EEF0F4;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.span`
  font-weight: bold;
`;

const ArrowRight = styled(IoIosArrowForward)`
  height: 100%
`;
const ArrowDown = styled(IoIosArrowDown)`
  height: 100%
`;

type ContentProps = {
  open: boolean;
}

const shouldForwardProp = (prop: string) => prop !== "open";

const Content = styled.span.withConfig({
  shouldForwardProp,
})<ContentProps>`
  background: #F6F6F6;
  padding: ${({ open }) => (open ? " 0.5em" : "0")};
  max-height: ${({ open }) => (open ? "200px" : "0")};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease;
  overflow: hidden;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const DetailDropdown: React.FC<ComponentProps> = ({ name, content }) => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen((current) => !current);
  };

  return (
    <DetailDropdownContainer>
      <NameContainer onClick={onClick}>
        <Name>{name}</Name>
        {
          open ? <ArrowDown /> : <ArrowRight />
        }
      </NameContainer>
      <Content open={open}>{content}</Content>
    </DetailDropdownContainer>
  );
};

export default DetailDropdown;
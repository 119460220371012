import React from "react";
import { FontWeight, Size, SizeStyleMap, TextAlign } from "../../../types/styling";

type ComponentProps = {
  text: string,
  padding?: React.CSSProperties["padding"],
  margin?: React.CSSProperties["margin"],
  fontWeight?: FontWeight,
  textAlign?: TextAlign,
  size?: Size,
}

const buildStyle = (
  size: Size,
  sizeStyleMap: SizeStyleMap,
  textAlign: TextAlign,
  padding: React.CSSProperties["padding"],
  margin: React.CSSProperties["margin"],
  fontWeight: FontWeight,
) => {
  return {
    ...sizeStyleMap[size],
    textAlign,
    padding,
    margin,
    fontWeight,
  };
};

const header1SizeStyleMap: SizeStyleMap = {
  [Size.SMALL]: {
    fontSize: "2em"
  },
  [Size.MEDIUM]: {
    fontSize: "2.5em"
  },
  [Size.LARGE]: {
    fontSize: "3em"
  }
};


export const Header1: React.FC<ComponentProps> = ({
  text,
  size = Size.SMALL,
  textAlign = TextAlign.CENTER,
  padding = "0",
  margin = "0",
  fontWeight = FontWeight.BOLD,
}) => {
  const style = buildStyle(size,
    header1SizeStyleMap,
    textAlign,
    padding,
    margin,
    fontWeight
  );
  
  return (
    <h1 style={style} >{text}</h1>
  );
};

const header2SizeStyleMap: SizeStyleMap = {
  [Size.SMALL]: {
    fontSize: "1em"
  },
  [Size.MEDIUM]: {
    fontSize: "1.75em"
  },
  [Size.LARGE]: {
    fontSize: "2.55em"
  }
};

export const Header2: React.FC<ComponentProps> = ({
  text,
  size = Size.SMALL,
  textAlign = TextAlign.CENTER,
  padding = "0",
  margin = "0",
  fontWeight = FontWeight.BOLD,
}) => {
  const style = buildStyle(size,
    header2SizeStyleMap,
    textAlign,
    padding,
    margin,
    fontWeight
  );

  return (
    <h2 style={style} >{text}</h2>
  );
};

const header3SizeStyleMap: SizeStyleMap = {
  [Size.SMALL]: {
    fontSize: "0.75em"
  },
  [Size.MEDIUM]: {
    fontSize: "1.5em"
  },
  [Size.LARGE]: {
    fontSize: "2em"
  }
};

export const Header3: React.FC<ComponentProps> = ({
  text,
  size = Size.SMALL,
  textAlign = TextAlign.CENTER,
  padding = "0",
  margin = "0",
  fontWeight = FontWeight.BOLD,
}) => {
  const style = buildStyle(size,
    header3SizeStyleMap,
    textAlign,
    padding,
    margin,
    fontWeight
  );

  return (
    <h3 style={style} >{text}</h3>
  );
};

const header4SizeStyleMap: SizeStyleMap = {
  [Size.SMALL]: {
    fontSize: "0.5em"
  },
  [Size.MEDIUM]: {
    fontSize: "1.25em"
  },
  [Size.LARGE]: {
    fontSize: "1.75em"
  }
};

export const Header4: React.FC<ComponentProps> = ({
  text,
  size = Size.SMALL,
  textAlign = TextAlign.CENTER,
  padding = "0",
  margin = "0",
  fontWeight = FontWeight.BOLD,
}) => {
  const style = buildStyle(size,
    header4SizeStyleMap,
    textAlign,
    padding,
    margin,
    fontWeight
  );
  return (
    <h4 style={style} >{text}</h4>
  );
};
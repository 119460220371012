import React from "react";
import { useParams } from "react-router-dom";
import BaseProductOrderDisplay from "./BaseProductOrderDisplay";
import { useGetBaseProductQuery } from "../../generated/graphql";

const BaseProductOrderPage = () => {
  const { id } = useParams();

  if (!id) return null;

  const { data } = useGetBaseProductQuery({
    variables: { id },
  });

  return (
    <>
      {
        data ? <BaseProductOrderDisplay baseProduct={data.baseProduct} />  : null
      }
    </>
  );
};

export default BaseProductOrderPage;
import React from "react";
import styled from "styled-components";

interface TableProps {
  headers: string[];
  rows: string[][];
  hasBorder?: boolean;
}

interface StylingProps {
  hasBorder?: boolean
}

// const excludedProps = new Set<keyof StylingProps>(Object.keys({} as StylingProps) as (keyof StylingProps)[]);
// const isStylingProp = (prop: string) => {
//   return excludedProps.has(prop as keyof StylingProps);
// };
const shouldForwardProp = (prop: string) => prop !== "hasBorder";

const StyledTable = styled.table.withConfig({
  shouldForwardProp,
})<StylingProps>`
  border: ${(props) => (props.hasBorder ? `solid black 1px` : 'none')};
  border-radius: ${(props) => (props.hasBorder ? `6px` : '0')};;
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

const StyledTHead = styled.thead`
`;

const StyledTh = styled.th.withConfig({
  shouldForwardProp,
})<StylingProps>`
  padding: 8px;
  text-align: left;

  border-top: ${(props) => (props.hasBorder ? `solid black 1px` : 'none')};

  &:first-child {
    border-top: none;
  }
`;

const StyledTbody = styled.tbody`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
`;

const StyledTd = styled.td.withConfig({
  shouldForwardProp,
})<StylingProps>`
  padding: 8px;

  border-left: ${(props) => (props.hasBorder ? `solid black 1px` : 'none')};
  border-top: ${(props) => (props.hasBorder ? `solid black 1px` : 'none')};

  &:first-child {
    border-top: none;
  }

`;

const StyledTr = styled.tr`
  display: flex;
  flex-direction: column;
`;

const HorizontalTable: React.FC<TableProps> = ({ headers, rows, hasBorder = true }) => {
  return (
    <StyledTable hasBorder={hasBorder} >
      <StyledTHead>
        <StyledTr>
          {headers.map((header, index) => (
            <StyledTh hasBorder={hasBorder} key={index}>{header}</StyledTh>
          ))}
        </StyledTr>
      </StyledTHead>
      <StyledTbody>
        {rows.map((row, rowIndex) => (
          <StyledTr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <StyledTd hasBorder={hasBorder} key={cellIndex}>{cell}</StyledTd>
            ))}
          </StyledTr>
        ))}
      </StyledTbody>
    </StyledTable>
  );
};

export default HorizontalTable;

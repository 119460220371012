import React from "react";
import styled from "styled-components";

interface TableProps {
  headers: string[];
  rows: string[][];
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border: solid black 1px;
  border-radius: 6px;
`;

const StyledTh = styled.th`
  padding: 8px;
  text-align: left;

  border-left:solid black 1px;

  &:first-child {
    border-left: none;
  }
`;

const StyledTd = styled.td`
  padding: 8px;

  border-left:solid black 1px;
  border-top:solid black 1px;

  &:first-child {
    border-left: none;
  }
`;

const StyledTr = styled.tr`
`;

const VerticalTable: React.FC<TableProps> = ({ headers, rows }) => {
  return (
    <StyledTable>
      <thead>
        <StyledTr>
          {headers.map((header, index) => (
            <StyledTh key={index}>{header}</StyledTh>
          ))}
        </StyledTr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <StyledTr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <StyledTd key={cellIndex}>{cell}</StyledTd>
            ))}
          </StyledTr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default VerticalTable;

import React from "react";
import styled from "styled-components";
import HorizontalTable from "../../../components/common/tables/HorizontalTable";
import { GetBaseProductQuery } from "../../../generated/graphql";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const PricingContainer = styled.div`
  width: 80%;
  text-align: left;
  margin: 50px 0;
`;

const Heading = styled.h3`
  margin-bottom: 1em;
`;

// TODO: this is duplicated with SummaryQuantityTierPricing in the base product order page

const quantityTierPricingToPricingRange = (quantityTierPricing: GetBaseProductQuery['baseProduct']['quantityTierPricings'][number]) => {
  if (!!quantityTierPricing.maxRangeQuantity && !!quantityTierPricing.minRangeQuantity) {
    return `${quantityTierPricing.minRangeQuantity} - ${quantityTierPricing.maxRangeQuantity}`;
  }

  if (!quantityTierPricing.maxRangeQuantity) {
    return `0 - ${quantityTierPricing.minRangeQuantity}`;
  }

  if (!quantityTierPricing.minRangeQuantity) {
    return `${quantityTierPricing.maxRangeQuantity}+`;
  }

  return "TBD";
};

const Pricing: React.FC<ComponentProps> = ({ baseProduct }) => {
  const headers = [
    "Order Quantity",
    `Price ${baseProduct.saleUnit}`
  ];

  const rows = baseProduct.quantityTierPricings.map((quantityTierPricing) => {
    const pricingRange = quantityTierPricingToPricingRange(quantityTierPricing);
    const perUnitCost = `$${quantityTierPricing.priceAmount}`;
    return [pricingRange, perUnitCost];
  });

  return (
    <PricingContainer>
      <Heading>Pricing</Heading>
      <HorizontalTable headers={headers} rows={rows} />
    </PricingContainer>
  );
};

export default Pricing;
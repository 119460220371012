import React from "react";
import styled from "styled-components";
import DetailDropdown from "./DetailDropdown";
import { Link } from "react-router-dom";
import CTAButton from "../../../components/common/buttons/CTAButton";
import { GetBaseProductQuery } from "../../../generated/graphql";
import HorizontalDivider from "../../../components/common/display/HorizontalDivider";
import { Header1, Header2, Header3 } from "../../../components/common/display/Headers";
import { Size, TextAlign } from "../../../types/styling";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

// TODO: add custom resolvers for min price, max price, etc
const BaseProductPriceText = styled.span`
  text-align: left;
`;

const BaseProductPrice: React.FC<ComponentProps> = ({ baseProduct }) => {
  const pricings = baseProduct.quantityTierPricings.map(({ priceAmount }) => priceAmount).sort().reverse();
  const minPrice = pricings[0];
  const maxPrice = pricings[pricings.length - 1];
  const productPriceRange = (minPrice && maxPrice) ? `$${minPrice} - $${maxPrice}` : "TBD";

  // TODO: need to add pluralization
  // TODO: check on sale unit display
  return (
    <>
      <BaseProductPriceText>{`${productPriceRange} ${baseProduct.saleUnit}`}</BaseProductPriceText>
    </>
  );
};

const CollaborationCtaButton = styled(CTAButton)`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const BaseProductDescription = styled.p`
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
`;

const DetailDropdownContainer = styled.div`
  margin-top: 2em;
`;

const MainContentProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
`;

const MainContentProduct: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <MainContentProductContainer>
      <Header2 text={baseProduct.companyName} textAlign={TextAlign.LEFT} />
      <Header1 text={baseProduct.productName} textAlign={TextAlign.LEFT} />
      <BaseProductPrice baseProduct={baseProduct} />
      <Link to={`/base_products/${baseProduct.id}/order`}>
        <CollaborationCtaButton>Request to Collaborate</CollaborationCtaButton>
      </Link>
      <Header3 text="Description" margin="1em 0 0" size={Size.MEDIUM} textAlign={TextAlign.LEFT} />
      <BaseProductDescription>{baseProduct.description}</BaseProductDescription>
      <HorizontalDivider />
      <DetailDropdownContainer>
        <DetailDropdown name={"Sample"} content={baseProduct.sample} />
        <DetailDropdown name={"MOQ"} content={baseProduct.minimumOrderQuantity} />
        <DetailDropdown name={"Shipping"} content={baseProduct.shipping} />
        <DetailDropdown name={"Lead Time"} content={baseProduct.leadTime} />
      </DetailDropdownContainer>
    </MainContentProductContainer>
  );
};

export default MainContentProduct;
import React from "react";
import styled from "styled-components";
import { GetBaseProductQuery } from "../../../generated/graphql";

type ComponentProps = {
  customization: GetBaseProductQuery["baseProduct"]["customizations"][number];
}

const CustomizationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1em 0;
`;

const Name = styled.span`
  width: 25%;
`;

const Value = styled.span``;

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Customization: React.FC<ComponentProps> = ({ customization }) => {
  const values = customization.values || [];
  
  return (
    <CustomizationContainer>
      <Name>{customization.label}</Name>
      <ValuesContainer>
        {
          values.map((value, index) => <Value key={index} >{value}</Value>)
        }
      </ValuesContainer>
    </CustomizationContainer>
  );
};

export default Customization;
import React from "react";
import TextContentContainer from "../shared/TextContentContainer";
import Image from "../../../components/common/display/ImageWithFallbackImage";
import SectionContainer from "../shared/SectionContainer";
import { Header3 } from "../../../components/common/display/Headers";
import { Size, TextAlign } from "../../../types/styling";
import { TextLine } from "../../../components/common/display/Text";
import styled from "styled-components";

const ImageContent = () => {
  return (
    <Image imageURL="aboutUs/sellers.png" description="manufacturers" />
  );
};

const brandBullets = [
  "Access the best brands locally and globally to source products",
  "End-to-end logistics and support",
  "Upfront information on everything you need: timelines, costs, MOQs, and more"
];

const BrandBulletContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContent = () => {
  return (
    <TextContentContainer>
      <Header3 text="Brands" textAlign={TextAlign.LEFT} size={Size.LARGE} />
      <BrandBulletContainer>
        {
          brandBullets.map((brandBullet, index) => <TextLine text={brandBullet} key={index} />)
        }
      </BrandBulletContainer>
    </TextContentContainer>
  );
};

const Sellers = () => {
  return (
    <SectionContainer>
      <ImageContent />
      <TextContent />
    </SectionContainer>
  );
};

export default Sellers;

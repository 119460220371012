import React from "react";
import { UseFormRegister } from "react-hook-form";
import { BaseProductOrderInputs } from "../../../types/forms/baseProductOrder";
import TextInput from "../../../components/common/inputs/TextInput";
import { camelCaseToCapitalizedWordsReadableDisplay } from "../../../helpers/strings";
import { GetBaseProductQuery } from "../../../generated/graphql";


type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
  register: UseFormRegister<BaseProductOrderInputs>;
}

const Customizations: React.FC<ComponentProps> = ({ baseProduct, register }) => {
  return (
    <>
      {
        Object.keys(baseProduct.customizations).map((customizationKey) => {
          const customizationDisplayName = camelCaseToCapitalizedWordsReadableDisplay(customizationKey);
          return <TextInput placeholder={customizationDisplayName} {...register(`customizations.${customizationKey}`, { required: `${customizationDisplayName} is required` })} margin="0.5em 0" />;
        })
      }
    </>
  );
};

export default Customizations;
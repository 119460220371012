import React from "react";
import styled from "styled-components";
import { Header4 } from "../../../components/common/display/Headers";
import { Size } from "../../../types/styling";
import { TextBlock } from "../../../components/common/display/Text";

type Feature = {
  title: string;
  description: string;
  imageUrl: string;
}

type ComponentProps = {
  feature: Feature;
}

const Image = styled.img`
  max-width: 20%;
  max-height: 20%;
  object-fit: contain;
  margin: 2em;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 1.25em;
  background: white;
`;
// TODO: check that we have a background color of white set

const Card: React.FC<ComponentProps> = ({ feature }) => {
  return (
    <CardContainer>
      <Image src={feature.imageUrl} alt={feature.title} />
      <Header4 text={feature.title} size={Size.LARGE} margin="0.25em" />
      <TextBlock text={feature.description} margin="1em" />
    </CardContainer>
  );
};

const features: Feature[] = [
  {
    title: "Discover",
    description: "Discover brands that match your brands style and needs. OurVersion’s markeplace is curated with brands that are handpicked for their quality of craftsmanship.",
    imageUrl: "aboutUs/discover.png",
  },
  {
    title: "Logistics Support",
    description: "We support our partners from requesting collaboration to receiving the order. We help you move away from messy emails and spreadsheets to our platform.",
    imageUrl: "aboutUs/support.png",
  },
  {
    title: "Customize",
    description: "OurVersion marketplace has companies that offer full customization of a private label, partial customization of a white label, and branded collaboration products.",
    imageUrl: "aboutUs/customize.png",
  }
];

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: row;
  gap: 10em;
  margin: 75px;
`;

const Features = () => {
  return (
    <FeaturesContainer>
      {
        features.map((feature) => <Card feature={feature} />)
      }
    </FeaturesContainer>
  );
};

export default Features;
export enum Size {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum FontWeight {
  NORMAL = "normal",
  BOLD = "bold",
}

export enum TextAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export type ComponentStylingProps = {
  padding?: string;
  margin?: string;
}

export type SizeStyleMap = {
  [key in Size]: React.CSSProperties;
};
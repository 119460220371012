import React from "react";
import Intro from "./intro";
import Sellers from "./sellers";
import Manufacturers from "./manufacturers";
import Features from "./features";
import MainArea from "../../components/common/layout/MainArea";
import { Size } from "../../types/styling";
import { Header2 } from "../../components/common/display/Headers";
import styled from "styled-components";

const GreyContainer = styled.div`
  width: 100vw;
  background: #F8F9FF;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  position: relative; /* Ensures no unexpected margins from parent */
  left: calc(-50vw + 50%); /* Centers it to cover entire screen width */
  flex-direction: column;
  align-items: center;
`;

const HomePage = () => {
  return (
    <MainArea>
      <Intro />
      <Header2 size={Size.LARGE} text="Hi, we're OurVersion. Who are you?"/>
      <Sellers />
      <GreyContainer>
        <Manufacturers />
        <Header2 size={Size.LARGE} text="Marketplace for product collaboration" />
        <Features />
      </GreyContainer>
    </MainArea>
  );
};

export default HomePage;
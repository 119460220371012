import styled from "styled-components";
import { ComponentStylingProps } from "../../../types/styling";

const TextInput = styled.input<ComponentStylingProps>`
  border-radius: 1em;
  border: 1px solid black;
  width: 100%;
  padding: ${({ padding }) => padding || '0.5em'};
  height: 3.5em;
  margin: ${({ margin }) => margin || '0'};
`;

export default TextInput;
import React from "react";
import VerticalTable from "../../../components/common/tables/VerticalTable";
import styled from "styled-components";
import { GetBaseProductQuery } from "../../../generated/graphql";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const SummaryQuantityTierPricingsContainer = styled.div`
  width: 90%;
`;

// TODO: this is duplicated with Pricing in the base product page

const quantityTierPricingToPricingRange = (quantityTierPricing: GetBaseProductQuery['baseProduct']['quantityTierPricings'][number]) => {
  if (!!quantityTierPricing.maxRangeQuantity && !!quantityTierPricing.minRangeQuantity) {
    return `${quantityTierPricing.minRangeQuantity} - ${quantityTierPricing.maxRangeQuantity}`;
  }

  if (!quantityTierPricing.maxRangeQuantity) {
    return `0 - ${quantityTierPricing.minRangeQuantity}`;
  }

  if (!quantityTierPricing.minRangeQuantity) {
    return `${quantityTierPricing.maxRangeQuantity}+`;
  }

  return "TBD";
};

const SummaryQuantityTierPricings: React.FC<ComponentProps> = ({ baseProduct }) => {
  const headers = [
    "Order Quantity",
    `Price ${baseProduct.saleUnit}`
  ];

  const rows = baseProduct.quantityTierPricings.map((quantityTierPricing) => {
    const pricingRange = quantityTierPricingToPricingRange(quantityTierPricing);
    const perUnitCost = `$${quantityTierPricing.priceAmount}`;
    return [pricingRange, perUnitCost];
  });

  return (
    <SummaryQuantityTierPricingsContainer>
      <VerticalTable headers={headers} rows={rows} />
    </SummaryQuantityTierPricingsContainer>
  );
};

export default SummaryQuantityTierPricings;
import React from "react";
import TextContentContainer from "../shared/TextContentContainer";
import Image from "../../../components/common/display/ImageWithFallbackImage";
import SectionContainer from "../shared/SectionContainer";
import { Header3 } from "../../../components/common/display/Headers";
import { Size, TextAlign } from "../../../types/styling";
import { TextLine } from "../../../components/common/display/Text";
import styled from "styled-components";

const manufacturersBlurbs = [
  "Grow your brand recognition by partnering with local and global retailers",
  "Complete control over who to collaborate with",
  "Friction-less coordination and collaboration with buyers",
];

const ManufacturersBulletContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContent = () => {
  return (
    <TextContentContainer>
      <Header3 text="Manufacturers" textAlign={TextAlign.LEFT} size={Size.LARGE} />
      <ManufacturersBulletContainer>
        {
          manufacturersBlurbs.map((manufacturersBlurb) => <TextLine text={manufacturersBlurb}/>)
        }
      </ManufacturersBulletContainer>
    </TextContentContainer>
  );
};

const ImageContent = () => {
  return (
    <Image imageURL="aboutUs/manufacturers.png" description="description" />
  );
};

const Manufacturers = () => {
  return (
    <SectionContainer>
      <TextContent />
      <ImageContent />
    </SectionContainer>
  );
};

export default Manufacturers;
